import React, { useEffect, useRef, useState } from "react";
import { default as infoIcon } from "../../assets/image/i-icon.svg";
import DownloadAsPDF from "../../pages/DownloadAsPDF";
import EmailPopup from "../../pages/Email/EmailPopup";
import InforModal from "../../pages/InformationModal/InfoModal";
import { showNotification } from "../../utils/customHooks/Toast";
import { useAssessmentunit } from "../Auth/ActiveAssessmentContext";
import { useAuth } from "../Auth/AuthContext";
import Layout from "../Common/Layout";
import "./ResidualRisk.css";

import {
  approveAllKriOverrideRequest,
  averageAuRiskScores,
  calculateThresholdRisk,
  getActiveAssessmentData,
  getResidualRiskScores,
  getSystemGeneratedRiskScores,
  rejectAllKriOverrideRequest,
  resetPendingKriWeights,
} from "../../services/ApiService";
import MenuMobile from "../../assets/image/menu-2-line.svg";
import imageArrow from "../../assets/image/logout.svg";

import { useNavigate } from "react-router-dom";

// const blocks = [
//   "Consumer Banking - Risk Assessment",
//   "Wholesale Banking - Risk Assessment",
//   "Business Banking - Risk Assessment",
//   "Bank Wide - Overall Risk Assessment",
// ];

// const blocksUser1 = [
//   "Consumer Banking - Risk Assessment",
//   "Bank Wide Risk Assessment",
// ];
// const blocksUser2 = ["System Generated", "Revised Risk Assessment"];

const ResidualRisk = () => {
  const { user, logout } = useAuth();
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [riskScores, setRiskScores] = useState(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [resetEnabled, setResetEnabled] = useState(false);
  const [averageRiskScores, setAverageRiskScores] = useState(null);
  const [isAnyPendingStatusKri, setIsAnyPendingStatusKri] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate();
  function capitalizeFirstLetter(str) {
    return str.replace(/^./, (match) => match.toUpperCase());
  }

  let currentAssessmentUnit =
    capitalizeFirstLetter(user.username.split(".")[0]) +
    " " +
    capitalizeFirstLetter(user.username.split(".")[1]);

  // Function to handle user logout
  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  console.log("riskScores", riskScores);
  const [systemGeneratedRiskScores, setSystemGeneratedRiskScores] =
    useState(null);

  // Ref for a DOM element
  const contentRef = useRef(null);
  const { selectedUnit } = useAssessmentunit();
  const fetchActiveAssessmentData = async () => {
    try {
      const response = await getActiveAssessmentData();
      console.log("response for active assessment data : ", response);

      let temp = [];
      response.data.forEach((cat) => {
        cat.assessment_units.forEach((assType) => {
          assType.risk_categories.forEach((riskType) => {
            riskType.key_risk_indicators.forEach((kri) => {
              temp.push(kri.status);
            });
          });
        });
      });
      const pendingStatusKris = temp.filter((ele) => ele === "Pending");
      const approvedStatusKris = temp.filter((ele) => ele === "Approved");
      if (pendingStatusKris.length) {
        setResetEnabled(true);
        setIsAnyPendingStatusKri(true);
      } else {
        setIsAnyPendingStatusKri(false);
      }

      if (approvedStatusKris.length && !pendingStatusKris.length) {
        setSubmitEnabled(true);
      }
    } catch (error) {
      console.log("Error while fetching active assessments data : ", error);
    }
  };

  useEffect(() => {
    const fetchRiskScores = async () => {
      try {
        const response = await getResidualRiskScores();
        setRiskScores(response.data);
        console.log("response of getRiskScores API", response.data);
      } catch (error) {
        console.log("Error while fetching risk scores : ", error);
      }
    };

    const fetchSystemGenerateRiskScores = async () => {
      try {
        const response = await getSystemGeneratedRiskScores();
        setSystemGeneratedRiskScores(response.data);
        console.log(
          "Response for getSystemGeneratedRiskScores : ",
          response.data
        );
      } catch (error) {
        console.log(
          "Error while fetching system generated risk scores : ",
          error
        );
      }
    };

    const fetchAverageAuRiskScores = async () => {
      try {
        const response = await averageAuRiskScores();
        console.log("response.data for average risk scores", response.data);
        setAverageRiskScores(response.data);
      } catch (error) {
        console.log("Error while fetching average AU risk scores : ", error);
      }
    };

    fetchSystemGenerateRiskScores();
    fetchRiskScores();
    fetchAverageAuRiskScores();
    fetchActiveAssessmentData();
  }, []);

  const handleShowInformationModal = () => {
    setShowInformationModal(true);
  };
  const handleCloseInformationModal = () => setShowInformationModal(false);

  const handleApprove = async () => {
    try {
      setIsLoading1(true);
      await approveAllKriOverrideRequest();
      setIsApproved(true);
      fetchActiveAssessmentData();
      showNotification("Request Approved", "success");
    } catch (error) {
      console.log("Error while approving override requests : ", error);
      showNotification("Something went wrong", "error");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsLoading2(true);
      await rejectAllKriOverrideRequest();
      showNotification("Request Rejected", "success");
      setIsRejected(true);
      fetchActiveAssessmentData();
    } catch (error) {
      console.log("Error while rejecting the KRIs : ", error);
      showNotification("Something went wrong", "error");
    } finally {
      setIsLoading2(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading1(true);
      await calculateThresholdRisk();
      showNotification("Submitted Successfully", "success");
      setIsApproved(true);
    } catch (error) {
      showNotification("Something went wrong", "error");
    } finally {
      setIsLoading1(false);
    }

    // setTimeout(() => {
    //   showNotification("Submitted Successfully", "success");
    //   setIsLoading1(false);
    //   setIsApproved(true);
    // }, 1500);
  };
  const handleReset = async () => {
    try {
      setIsLoading2(true);
      await resetPendingKriWeights();
      showNotification("Reset Successfully", "success");
      setIsApproved(true);
    } catch (error) {
      console.log("Error while reset pending kri weights : ", error);
      showNotification("Something went wrong", "error");
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <Layout
      isSidebarVisible={isSidebarVisible}
      setIsSidebarVisible={setIsSidebarVisible}
    >
      <div className="total-overview" ref={contentRef}>
        <div className="top-heading">
          <div>
            <h2 className="heading-common exclude-from-pdf">
              <img
                width={20}
                height={20}
                src={MenuMobile}
                alt="icon"
                className="hide-desktop-icon"
                onClick={() => setIsSidebarVisible((prev) => !prev)}
              />
              Residual Risk{" "}
              {user.role === "compliance-user" && `(${selectedUnit})`}
            </h2>
          </div>

          <div className="button-configuration d-flex justify-content-between">
            <div>
              {user.role === "compliance-user" ? (
                <>
                  {/* {(selectedUnit === "Consumer Banking" ||
                    selectedUnit === "Wholesale Banking" ||
                    selectedUnit === "Business Banking") && (
                    <> */}
                  <button
                    className={`reset-btn ${
                      !isAnyPendingStatusKri ? "disabled disabled-button" : ""
                    }`}
                    onClick={handleReject}
                    disabled={!isAnyPendingStatusKri}
                  >
                    {!isLoading2 ? (
                      <React.Fragment>
                        <i className="fa fa-times-circle-o mx-1"></i>
                        Reject
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                  <button
                    className={`save-btn m-30 ${
                      !isAnyPendingStatusKri ? "disabled disabled-button" : ""
                    }`}
                    onClick={handleApprove}
                    disabled={!isAnyPendingStatusKri}
                  >
                    {!isLoading1 ? (
                      <React.Fragment>
                        <i className="fa fa-check-circle-o mx-1"></i>
                        Approve
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                  {/* </>
                  )} */}
                </>
              ) : (
                <>
                  <button
                    className={`reset-btn ${
                      isRejected || isApproved || !resetEnabled
                        ? "disabled disabled-button"
                        : ""
                    }`}
                    onClick={handleReset}
                    disabled={isRejected || isApproved || !resetEnabled}
                  >
                    {!isLoading2 ? (
                      <React.Fragment>
                        <i className="fa fa-times-circle-o mx-1"></i>
                        Reset
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                  <button
                    className={`save-btn m-30 ${
                      !submitEnabled ? "disabled disabled-button" : ""
                    }`}
                    onClick={handleSubmit}
                    disabled={!submitEnabled}
                  >
                    {!isLoading1 ? (
                      <React.Fragment>
                        <i className="fa fa-check-circle-o mx-1"></i>
                        Submit
                      </React.Fragment>
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
            </div>

            <div
              className="d-flex justify-content-center align-items-center p-0 top-icons-heading"
              style={{ marginRight: "25px" }}
            >
              <img
                src={infoIcon}
                className="exclude-from-pdf"
                alt="icon"
                onClick={handleShowInformationModal}
                style={{ cursor: "pointer" }}
              />
              <DownloadAsPDF contentRef={contentRef} />
              <EmailPopup contentRef={contentRef} />
              <img
                style={{
                  width: "29px",
                  height: "29px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={imageArrow}
                alt="icon"
                onClick={handleLogout}
              />
              <p>{user.username}</p>
            </div>
          </div>
        </div>

        {/* breadcrumb */}
        <div className="col-12 exclude-from-pdf">
          {/* <AssessmentLogs data={null} /> */}
        </div>

        {user.role === "compliance-user" ? (
          <div className="row common-tab scroll-cover-residual">
            {/* Show all kind of assessments when All is selected */}
            {selectedUnit === "All" &&
              riskScores &&
              Object.keys(riskScores).forEach((key) => {
                if (key === "RiskCategory") {
                  return;
                }
                return (
                  <div className="col-md-6">
                    <div className="residual-box">
                      <label>
                        {key === "All" ? "Bank Wide Overall" : key} - Risk
                        Assessment
                      </label>
                      <table className="table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Inherent Risk</th>
                            <th>Control Risk</th>
                            <th>Residual Risk</th>
                          </tr>
                        </thead>
                        <tbody>
                          {riskScores &&
                            riskScores["RiskCategory"].map((risk, index) => (
                              <tr>
                                <th>{risk}</th>
                                <td>
                                  <div
                                    className={`custom-cube ${
                                      riskScores[key][0]["inherentRisk"][
                                        index
                                      ] >= 0 &&
                                      riskScores[key][0]["inherentRisk"][
                                        index
                                      ] < 25
                                        ? "green"
                                        : riskScores[key][0]["inherentRisk"][
                                            index
                                          ] >= 25 &&
                                          riskScores[key][0]["inherentRisk"][
                                            index
                                          ] < 50
                                        ? "yellow"
                                        : riskScores[key][0]["inherentRisk"][
                                            index
                                          ] >= 50 &&
                                          riskScores[key][0]["inherentRisk"][
                                            index
                                          ] < 75
                                        ? "amber"
                                        : "red"
                                    }`}
                                  ></div>
                                </td>
                                <td>
                                  <div
                                    className={`custom-cube ${
                                      riskScores[key][1]["controlRisk"][
                                        index
                                      ] >= 0 &&
                                      riskScores[key][1]["controlRisk"][index] <
                                        25
                                        ? "green"
                                        : riskScores[key][1]["controlRisk"][
                                            index
                                          ] >= 25 &&
                                          riskScores[key][1]["controlRisk"][
                                            index
                                          ] < 50
                                        ? "yellow"
                                        : riskScores[key][1]["controlRisk"][
                                            index
                                          ] >= 50 &&
                                          riskScores[key][1]["controlRisk"][
                                            index
                                          ] < 75
                                        ? "amber"
                                        : "red"
                                    }`}
                                  ></div>
                                </td>
                                <td>
                                  <div
                                    className={`custom-cube ${
                                      riskScores[key][2]["residualRisk"][
                                        index
                                      ] >= 0 &&
                                      riskScores[key][2]["residualRisk"][
                                        index
                                      ] < 25
                                        ? "green"
                                        : riskScores[key][2]["residualRisk"][
                                            index
                                          ] >= 25 &&
                                          riskScores[key][2]["residualRisk"][
                                            index
                                          ] < 50
                                        ? "yellow"
                                        : riskScores[key][2]["residualRisk"][
                                            index
                                          ] >= 50 &&
                                          riskScores[key][2]["residualRisk"][
                                            index
                                          ] < 75
                                        ? "amber"
                                        : "red"
                                    }`}
                                  ></div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}

            {selectedUnit !== "All" && (
              <div className="col-md-6">
                <div className="residual-box">
                  <label>Bank Wide - Overall Risk Assessment</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskScores &&
                        riskScores["RiskCategory"].map((risk, index) => (
                          <tr>
                            <th>{risk}</th>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores["All"][0]["inherentRisk"][index] >=
                                    0 &&
                                  riskScores["All"][0]["inherentRisk"][index] <
                                    25
                                    ? "green"
                                    : riskScores["All"][0]["inherentRisk"][
                                        index
                                      ] >= 25 &&
                                      riskScores["All"][0]["inherentRisk"][
                                        index
                                      ] < 50
                                    ? "yellow"
                                    : riskScores["All"][0]["inherentRisk"][
                                        index
                                      ] >= 50 &&
                                      riskScores["All"][0]["inherentRisk"][
                                        index
                                      ] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores["All"][1]["controlRisk"][index] >=
                                    0 &&
                                  riskScores["All"][1]["controlRisk"][index] <
                                    25
                                    ? "green"
                                    : riskScores["All"][1]["controlRisk"][
                                        index
                                      ] >= 25 &&
                                      riskScores["All"][1]["controlRisk"][
                                        index
                                      ] < 50
                                    ? "yellow"
                                    : riskScores["All"][1]["controlRisk"][
                                        index
                                      ] >= 50 &&
                                      riskScores["All"][1]["controlRisk"][
                                        index
                                      ] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores["All"][2]["residualRisk"][index] >=
                                    0 &&
                                  riskScores["All"][2]["residualRisk"][index] <
                                    25
                                    ? "green"
                                    : riskScores["All"][2]["residualRisk"][
                                        index
                                      ] >= 25 &&
                                      riskScores["All"][2]["residualRisk"][
                                        index
                                      ] < 50
                                    ? "yellow"
                                    : riskScores["All"][2]["residualRisk"][
                                        index
                                      ] >= 50 &&
                                      riskScores["All"][2]["residualRisk"][
                                        index
                                      ] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {selectedUnit !== "All" && (
              <div className="col-md-6">
                <div className="residual-box">
                  <label>{selectedUnit} - Risk Assessment</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskScores &&
                        riskScores["RiskCategory"].map((risk, index) => (
                          <tr>
                            <th>{risk}</th>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores[selectedUnit][0]["inherentRisk"][
                                    index
                                  ] >= 0 &&
                                  riskScores[selectedUnit][0]["inherentRisk"][
                                    index
                                  ] < 25
                                    ? "green"
                                    : riskScores[selectedUnit][0][
                                        "inherentRisk"
                                      ][index] >= 25 &&
                                      riskScores[selectedUnit][0][
                                        "inherentRisk"
                                      ][index] < 50
                                    ? "yellow"
                                    : riskScores[selectedUnit][0][
                                        "inherentRisk"
                                      ][index] >= 50 &&
                                      riskScores[selectedUnit][0][
                                        "inherentRisk"
                                      ][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores[selectedUnit][1]["controlRisk"][
                                    index
                                  ] >= 0 &&
                                  riskScores[selectedUnit][1]["controlRisk"][
                                    index
                                  ] < 25
                                    ? "green"
                                    : riskScores[selectedUnit][1][
                                        "controlRisk"
                                      ][index] >= 25 &&
                                      riskScores[selectedUnit][1][
                                        "controlRisk"
                                      ][index] < 50
                                    ? "yellow"
                                    : riskScores[selectedUnit][1][
                                        "controlRisk"
                                      ][index] >= 50 &&
                                      riskScores[selectedUnit][1][
                                        "controlRisk"
                                      ][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  riskScores[selectedUnit][2]["residualRisk"][
                                    index
                                  ] >= 0 &&
                                  riskScores[selectedUnit][2]["residualRisk"][
                                    index
                                  ] < 25
                                    ? "green"
                                    : riskScores[selectedUnit][2][
                                        "residualRisk"
                                      ][index] >= 25 &&
                                      riskScores[selectedUnit][2][
                                        "residualRisk"
                                      ][index] < 50
                                    ? "yellow"
                                    : riskScores[selectedUnit][2][
                                        "residualRisk"
                                      ][index] >= 50 &&
                                      riskScores[selectedUnit][2][
                                        "residualRisk"
                                      ][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="row common-tab scroll-cover-residual">
            {averageRiskScores && averageRiskScores[currentAssessmentUnit] && (
              <div className="col-md-6">
                <div className="residual-box">
                  <label>{currentAssessmentUnit} - Risk Assessment</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ background: "white" }}>
                          <div
                            className={`custom-cube ${
                              averageRiskScores[currentAssessmentUnit][0][
                                "inherentRisk"
                              ] >= 0 &&
                              averageRiskScores[currentAssessmentUnit][0][
                                "inherentRisk"
                              ] < 25
                                ? "green"
                                : averageRiskScores[currentAssessmentUnit][0][
                                    "inherentRisk"
                                  ] >= 25 &&
                                  averageRiskScores[currentAssessmentUnit][0][
                                    "inherentRisk"
                                  ] < 50
                                ? "yellow"
                                : averageRiskScores[currentAssessmentUnit][0][
                                    "inherentRisk"
                                  ] >= 50 &&
                                  averageRiskScores[currentAssessmentUnit][0][
                                    "inherentRisk"
                                  ] < 75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                        <td>
                          <div
                            className={`custom-cube ${
                              averageRiskScores[currentAssessmentUnit][1][
                                "controlRisk"
                              ] >= 0 &&
                              averageRiskScores[currentAssessmentUnit][1][
                                "controlRisk"
                              ] < 25
                                ? "green"
                                : averageRiskScores[currentAssessmentUnit][1][
                                    "controlRisk"
                                  ] >= 25 &&
                                  averageRiskScores[currentAssessmentUnit][1][
                                    "controlRisk"
                                  ] < 50
                                ? "yellow"
                                : averageRiskScores[currentAssessmentUnit][1][
                                    "controlRisk"
                                  ] >= 50 &&
                                  averageRiskScores[currentAssessmentUnit][1][
                                    "controlRisk"
                                  ] < 75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                        <td>
                          <div
                            className={`custom-cube ${
                              averageRiskScores[currentAssessmentUnit][2][
                                "residualRisk"
                              ] >= 0 &&
                              averageRiskScores[currentAssessmentUnit][2][
                                "residualRisk"
                              ] < 25
                                ? "green"
                                : averageRiskScores[currentAssessmentUnit][2][
                                    "residualRisk"
                                  ] >= 25 &&
                                  averageRiskScores[currentAssessmentUnit][2][
                                    "residualRisk"
                                  ] < 50
                                ? "yellow"
                                : averageRiskScores[currentAssessmentUnit][2][
                                    "residualRisk"
                                  ] >= 50 &&
                                  averageRiskScores[currentAssessmentUnit][2][
                                    "residualRisk"
                                  ] < 75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* all data average */}
            {averageRiskScores && averageRiskScores["All"] && (
              <div className="col-md-6">
                <div className="residual-box">
                  <label>Bank Wide Risk Assessment</label>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Inherent Risk</th>
                        <th>Control Risk</th>
                        <th>Residual Risk</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ background: "white" }}>
                          <div
                            className={`custom-cube ${
                              averageRiskScores["All"][0]["inherentRisk"] >=
                                0 &&
                              averageRiskScores["All"][0]["inherentRisk"] < 25
                                ? "green"
                                : averageRiskScores["All"][0]["inherentRisk"] >=
                                    25 &&
                                  averageRiskScores["All"][0]["inherentRisk"] <
                                    50
                                ? "yellow"
                                : averageRiskScores["All"][0]["inherentRisk"] >=
                                    50 &&
                                  averageRiskScores["All"][0]["inherentRisk"] <
                                    75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                        <td>
                          <div
                            className={`custom-cube ${
                              averageRiskScores["All"][1]["controlRisk"] >= 0 &&
                              averageRiskScores["All"][1]["controlRisk"] < 25
                                ? "green"
                                : averageRiskScores["All"][1]["controlRisk"] >=
                                    25 &&
                                  averageRiskScores["All"][1]["controlRisk"] <
                                    50
                                ? "yellow"
                                : averageRiskScores["All"][1]["controlRisk"] >=
                                    50 &&
                                  averageRiskScores["All"][1]["controlRisk"] <
                                    75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                        <td>
                          <div
                            className={`custom-cube ${
                              averageRiskScores["All"][2]["residualRisk"] >=
                                0 &&
                              averageRiskScores["All"][2]["residualRisk"] < 25
                                ? "green"
                                : averageRiskScores["All"][2]["residualRisk"] >=
                                    25 &&
                                  averageRiskScores["All"][2]["residualRisk"] <
                                    50
                                ? "yellow"
                                : averageRiskScores["All"][2]["residualRisk"] >=
                                    50 &&
                                  averageRiskScores["All"][2]["residualRisk"] <
                                    75
                                ? "amber"
                                : "red"
                            }`}
                          ></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="col-md-6">
              <div className="residual-box">
                <label>System Generated</label>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Inherent Risk</th>
                      <th>Control Risk</th>
                      <th>Residual Risk</th>
                    </tr>
                  </thead>
                  <tbody>
                    {systemGeneratedRiskScores &&
                      systemGeneratedRiskScores["RiskCategory"].map(
                        (risk, index) => (
                          <tr>
                            <th>{risk}</th>
                            <td>
                              <div
                                className={`custom-cube ${
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][0]["inherentRisk"][index] >= 0 &&
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][0]["inherentRisk"][index] < 25
                                    ? "green"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][0]["inherentRisk"][index] >= 25 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][0]["inherentRisk"][index] < 50
                                    ? "yellow"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][0]["inherentRisk"][index] >= 50 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][0]["inherentRisk"][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][1]["controlRisk"][index] >= 0 &&
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][1]["controlRisk"][index] < 25
                                    ? "green"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][1]["controlRisk"][index] >= 25 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][1]["controlRisk"][index] < 50
                                    ? "yellow"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][1]["controlRisk"][index] >= 50 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][1]["controlRisk"][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                            <td>
                              <div
                                className={`custom-cube ${
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][2]["residualRisk"][index] >= 0 &&
                                  systemGeneratedRiskScores[
                                    currentAssessmentUnit
                                  ][2]["residualRisk"][index] < 25
                                    ? "green"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][2]["residualRisk"][index] >= 25 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][2]["residualRisk"][index] < 50
                                    ? "yellow"
                                    : systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][2]["residualRisk"][index] >= 50 &&
                                      systemGeneratedRiskScores[
                                        currentAssessmentUnit
                                      ][2]["residualRisk"][index] < 75
                                    ? "amber"
                                    : "red"
                                }`}
                              ></div>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6">
              <div className="residual-box">
                <label>Revised Risk Assessment</label>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Inherent Risk</th>
                      <th>Control Risk</th>
                      <th>Residual Risk</th>
                    </tr>
                  </thead>
                  <tbody>
                    {riskScores &&
                      riskScores["RiskCategory"].map((risk, index) => (
                        <tr>
                          <th>{risk}</th>
                          <td>
                            <div
                              className={`custom-cube ${
                                riskScores[currentAssessmentUnit][0][
                                  "inherentRisk"
                                ][index] >= 0 &&
                                riskScores[currentAssessmentUnit][0][
                                  "inherentRisk"
                                ][index] < 25
                                  ? "green"
                                  : riskScores[currentAssessmentUnit][0][
                                      "inherentRisk"
                                    ][index] >= 25 &&
                                    riskScores[currentAssessmentUnit][0][
                                      "inherentRisk"
                                    ][index] < 50
                                  ? "yellow"
                                  : riskScores[currentAssessmentUnit][0][
                                      "inherentRisk"
                                    ][index] >= 50 &&
                                    riskScores[currentAssessmentUnit][0][
                                      "inherentRisk"
                                    ][index] < 75
                                  ? "amber"
                                  : "red"
                              }`}
                            ></div>
                          </td>
                          <td>
                            <div
                              className={`custom-cube ${
                                riskScores[currentAssessmentUnit][1][
                                  "controlRisk"
                                ][index] >= 0 &&
                                riskScores[currentAssessmentUnit][1][
                                  "controlRisk"
                                ][index] < 25
                                  ? "green"
                                  : riskScores[currentAssessmentUnit][1][
                                      "controlRisk"
                                    ][index] >= 25 &&
                                    riskScores[currentAssessmentUnit][1][
                                      "controlRisk"
                                    ][index] < 50
                                  ? "yellow"
                                  : riskScores[currentAssessmentUnit][1][
                                      "controlRisk"
                                    ][index] >= 50 &&
                                    riskScores[currentAssessmentUnit][1][
                                      "controlRisk"
                                    ][index] < 75
                                  ? "amber"
                                  : "red"
                              }`}
                            ></div>
                          </td>
                          <td>
                            <div
                              className={`custom-cube ${
                                riskScores[currentAssessmentUnit][2][
                                  "residualRisk"
                                ][index] >= 0 &&
                                riskScores[currentAssessmentUnit][2][
                                  "residualRisk"
                                ][index] < 25
                                  ? "green"
                                  : riskScores[currentAssessmentUnit][2][
                                      "residualRisk"
                                    ][index] >= 25 &&
                                    riskScores[currentAssessmentUnit][2][
                                      "residualRisk"
                                    ][index] < 50
                                  ? "yellow"
                                  : riskScores[currentAssessmentUnit][2][
                                      "residualRisk"
                                    ][index] >= 50 &&
                                    riskScores[currentAssessmentUnit][2][
                                      "residualRisk"
                                    ][index] < 75
                                  ? "amber"
                                  : "red"
                              }`}
                            ></div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      <InforModal
        showModal={showInformationModal}
        handleCloseModal={handleCloseInformationModal}
        path="residual-risk"
      />
    </Layout>
  );
};

export default ResidualRisk;
