import React, { useState } from "react";
import logo from "../../assets/image/logo.png";
import loginImage from "../../assets/image/login-image.svg";
import mailIcon from "../../assets/image/mail-icon.svg";
import passwordIcon from "../../assets/image/password-icon.svg";
import eyePasswordIcon from "../../assets/image/eye-password.svg";
import SliderComponent from "../../pages/SliderComponent";
import { showNotification } from "../../utils/customHooks/Toast";
// import { ToastContainer } from "react-toastify";
import { login } from "../../services/ApiService";

const Login = () => {
  // State for user
  const [user, setUser] = useState(() => {
    // Check if a user is saved in local storage
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  // State for form data
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Toggle the password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(formData);
      console.log("response for login user : ", response.data);

      // Store user data in local storage
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("accessToken", response.data.accessToken);
      setUser(response.data);
      window.location.href = "/dashboard";
    } catch (error) {
      showNotification("You have entered wrong Credentials.", "error");
      setFormData({
        username: "",
        password: "",
      });
    }
  };
  if (user) {
    console.log(user);
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0">
          <div className="login-left-dark">
            <div className="col-offset-3 col-6 p-0 text-center m-auto">
              <img className="logo-top" src={logo} alt={"logo"} />
              <img src={loginImage}  className="img-fluid logomanage-ipad" alt={"login"} />
              <h1 className="welcome-text">Welcome Back!</h1>
              {/* <div id="owl-carousel" className="owl-carousel owl-theme">
                                <div className="item">
                                    <div className="login-sroll-text">
                                        <p>"Understating AML Risk for better control"</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="login-sroll-text">
                                        <p>"Understating AML Risk for better control"</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="login-sroll-text">
                                        <p>"Understating AML Risk for better control"</p>
                                    </div>
                                </div>
                            </div> */}
              <SliderComponent />
            </div>
          </div>
        </div>

        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 p-0">
          <div className="login-right-light">
            <div>
            <h2>Get Started Now</h2>
            <p>Enter your credentials to access your account</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="icon-user">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Username"
                  required
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
                <img className="user-icon-login" src={mailIcon} alt={"icon"} />
              </div>
              <div className="icon-user">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control mb-0"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  required
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <img
                  className="user-icon-login"
                  src={passwordIcon}
                  alt={"icon"}
                />
                <img
                  className="eye-password"
                  src={eyePasswordIcon}
                  alt={"icon"}
                  onClick={togglePasswordVisibility}
                />
              </div>
              <button className="login-button" type="submit">
                Login
              </button>
            </form>
            </div>
          </div>
        </div>
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
};

export default Login;
