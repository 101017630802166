import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookIcon from "../../assets/image/book-icon.svg";
import { useAuth } from "../../components/Auth/AuthContext";
import {
  downloadAssessmentKriAttachment,
  updateKeyRiskIndicator,
} from "../../services/ApiService";
import { showNotification } from "../../utils/customHooks/Toast";
import FileUpload from "../FileUpload";
import "./ChangeRatingModal.css";
import environment from "../../environments/environment";

const ChangeRatingModal = ({
  showModal,
  handleCloseModal,
  selectedKRI,
  ratingLabel,
  ratingOptions,
}) => {
  const { user } = useAuth();
  const initialState = {
    overrideRisk: selectedKRI.overrideRisk ?? selectedKRI.risk,
    remark: selectedKRI.remark,
    status: selectedKRI.status,
  };
  const [updatedKRI, setUpdatedKRI] = useState(initialState);
  const [selectedAction, setSelectedAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedKRI) {
      const initialState = {
        overrideRisk: selectedKRI.overrideRisk ?? selectedKRI.risk,
        remark: selectedKRI.remark,
        status: selectedKRI.status,
      };
      setUpdatedKRI(initialState);
    }
  }, [selectedKRI, user.role]);

  const handleTextareaChange = (name, value) => {
    const updatedKRIData = {
      ...updatedKRI,
      [name]: value,
    };
    setUpdatedKRI(updatedKRIData);
  };

  function formatDate(date) {
    date = new Date(date);
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = date.toLocaleTimeString("en-US", options); // Get formatted time

    const day = String(date.getDate()).padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${time} ${day}-${month}-${year}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("updatedKRI", updatedKRI);
    const formData = new FormData();
    formData.append("overrideRisk", updatedKRI.overrideRisk);
    formData.append("remark", updatedKRI.newRemark);
    formData.append("username", user.username);

    if (updatedKRI.file) {
      formData.append("file", updatedKRI.file);
    }
    if (selectedAction === "approved") {
      formData.append("status", "Approved");
    } else if (selectedAction === "rejected") {
      formData.append("status", "Rejected");
    } else {
      formData.append("status", "Pending");
    }

    try {
      await updateKeyRiskIndicator(selectedKRI.id, formData);
      handleCloseModal();
      setUpdatedKRI(initialState);
      showNotification(`Updated Successfully.`, "success");
      setIsLoading(false); // Set loading to false when the request is complete
    } catch (error) {
      showNotification(`All fields are required`, "error");
      setIsLoading(false); // Set loading to false when the request is complete
    }
  };

  const handleReset = () => {
    setUpdatedKRI(initialState); // Reset the form fields to initial values
  };

  const downloadAttachment = (remarkId) => {
    const processDownload = (response, fileName) => {
      const url = window.URL.createObjectURL(new Blob([response]));

      // Create a temporary link and trigger the download without manual file name handling
      const link = document.createElement("a");
      link.href = url;
      console.log("fileName", fileName);

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    };

    try {
      downloadAssessmentKriAttachment(remarkId).then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1].replace(/"/g, "")
          : `document.pdf`;
        processDownload(res.data, fileName);
      });
    } catch (error) {
      console.log("Error while downloading attachement : ", error.message);
      showNotification(error.message, "error");
    }
  };

  if (!selectedKRI) {
    return null;
  }

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedKRI.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="statistical-box">
              <p className="rating-btn">
                <Button
                  variant="text"
                  className={`${selectedKRI.risk} active square-btn`}
                ></Button>
                Existing Rating
              </p>
              <div className="rating-btn">
                <p>{ratingLabel}</p>
                {ratingOptions.map((text, index) => (
                  <Button
                    key={index}
                    variant="text"
                    name="overrideRisk"
                    className={`${text} ${
                      updatedKRI.overrideRisk === text ? "active" : ""
                    }`}
                    onClick={() => handleTextareaChange("overrideRisk", text)}
                  >
                    {text}
                  </Button>
                ))}
              </div>
            </div>

            <div>
              {updatedKRI.remark && (
                <>
                  <div class="table-label">Previous Remarks</div>
                  <table class="modal-table table table-striped">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>File Name</th>
                        <th>Date Created</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedKRI.remark.map((data) => (
                        <tr>
                          <td>{data.name}</td>
                          <td>
                            {data.uploaded_file ? (
                              <>
                                <OverlayTrigger
                                  placement="bottom-end"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      {data.uploaded_file}
                                    </Tooltip>
                                  }
                                >
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href="#"
                                    style={{
                                      border: "none",
                                      justifyContent: "center",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      downloadAttachment(data.id);
                                    }}
                                  >
                                    {data.uploaded_file.slice(0, 8)} ..{" "}
                                    <img
                                      src="/download-btn.svg"
                                      alt=""
                                      style={{
                                        display: "inline-block !important",
                                      }}
                                    />
                                  </a>
                                </OverlayTrigger>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{formatDate(data.created_at)}</td>
                          <td>{data.remark}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>

            <div className="Write-Justification">
              <div className="inner-body">
                <h3>
                  <img src={BookIcon} alt="icon" /> Write your Justification
                </h3>
                <textarea
                  value={updatedKRI.newRemark}
                  name="newRemark"
                  cols="96"
                  rows="5"
                  required
                  maxLength={300}
                  onChange={(e) =>
                    handleTextareaChange("newRemark", e.target.value)
                  }
                  placeholder="Enter your justification here..."
                />
              </div>
            </div>
            <div className="popup-uplod-btn">
              <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                Upload any file type up to a maximum size of{" "}
                {environment.MAX_FILE_SIZE / (1024 * 1024)}MB
              </span>
              <FileUpload setUpdatedData={setUpdatedKRI} />

              {user.role !== "compliance-user" && (
                <>
                  <button type="button" onClick={handleReset}>
                    Reset
                  </button>
                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedKRI.status === "Approved"
                        ? "disabled disabled-button"
                        : ""
                    }`}
                    onClick={() => setSelectedAction("Pending")}
                    disabled={isLoading || updatedKRI.status === "Approved"}
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {user.role === "compliance-user" && (
                <>
                  <button
                    className={`reset-btn text-white ${
                      updatedKRI.status !== "Pending" &&
                      updatedKRI.status !== "Approved" &&
                      updatedKRI.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("rejected")}
                    disabled={
                      updatedKRI.status !== "Pending" &&
                      updatedKRI.status !== "Approved" &&
                      updatedKRI.status !== "Rejected"
                    }
                  >
                    Reject
                  </button>

                  <button
                    type="submit"
                    className={`save-btn-pop ${
                      updatedKRI.status !== "Pending" &&
                      updatedKRI.status !== "Approved" &&
                      updatedKRI.status !== "Rejected"
                        ? "disabled disabled-button"
                        : " "
                    }`}
                    onClick={() => setSelectedAction("approved")}
                    disabled={
                      isLoading ||
                      (updatedKRI.status !== "Pending" &&
                        updatedKRI.status !== "Approved" &&
                        updatedKRI.status !== "Rejected")
                    }
                  >
                    {!isLoading ? (
                      "Approve"
                    ) : (
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: 24 }}
                      />
                    )}
                  </button>
                </>
              )}
              {/* {user.role === "compliance-user" && (
                <button
                  className="reset-btn text-white"
                  onClick={() =>
                    setUpdatedKRI({ ...updatedKRI, status: "Rejected" })
                  }
                >
                  Reject
                </button>
              )}
              <button type="submit" className="save-btn-pop">
                {user.role !== "compliance-user" ? "Save" : "Approve"}
              </button> */}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* <ToastContainer /> */}
    </>
  );
};

export default ChangeRatingModal;
