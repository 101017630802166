import React from "react";
import Navigation from "./Navigation";
import CloseLine from "../../assets/image/close-line.svg";

const Layout = ({ children, isSidebarVisible, setIsSidebarVisible }) => {
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div
            className={`col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 px-0 max-width-sidebar ${
              isSidebarVisible ? "sidebar-visible" : "sidebar-hidden"
            }`}
          >
            <div className="fixed-layout">
              <img
                className="hide-desktop-icon cross-right-ipad"
                width={20}
                height={20}
                src={CloseLine}
                alt="icon"
                onClick={toggleSidebar}
              />
              <Navigation />
            </div>
          </div>
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10 px-0 right-content-section">
            {children}
          </div>
      <footer>
        <p className="copy-right-footer">
          &copy; 2025 IFCRA. All rights reserved.
        </p>
      </footer>
        </div>
      </div>
    </>
  );
};

export default Layout;
