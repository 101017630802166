import { toast } from 'react-toastify';

// Function to show a toast notification
export const showNotification  = (message, type = 'default') => {
  switch (type) {
    case 'success':
      toast.success(message, {
        position: 'top-right',
        autoClose: 1200,
        hideProgressBar: true,
        theme: "dark",
      });
      break;
    case 'error':
      toast.error(message, {
        position: 'top-right',
        autoClose: 1200,
        hideProgressBar: true,
        theme: "dark",
      });
      break;
    case 'info':
      toast.info(message, {
        position: 'top-right',
        autoClose: 1200,
        hideProgressBar: true,
        theme: "dark",
      });
      break;
    default:
      toast(message, {
        position: 'top-center',
        autoClose: 1200,
        hideProgressBar: true,
        theme: "dark",
      });
  }
};
