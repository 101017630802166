import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BookIcon from "../../assets/image/book-icon.svg";
import {
  buisnessUsersList,
  createAssessmentUnit,
} from "../../services/ApiService";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./AssessmentModal.css";
import { useAuth } from "../../components/Auth/AuthContext";
import "font-awesome/css/font-awesome.min.css";
import { showNotification } from "../../utils/customHooks/Toast";
import FileUpload from "../FileUpload";

const AssessmentModal = ({ riskType, data, fetchData }) => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [formData, setformData] = useState({
    name: "",
    weight: 0,
    overrideWeight: 0,
    status: "Pending",
    remark: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const getBuisnessUsersList = async () => {
      try {
        const response = await buisnessUsersList();
        setUsers(response.data);
      } catch (error) {
        console.log("Error while fetching users list : ", error);
      }
    };
    getBuisnessUsersList();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("remark", formData.remark);
    formdata.append("isActive", "1");
    formdata.append("file", formData.file);
    if (formData.assigned_user_id) {
      formdata.append("assigned_user_id", formData.assigned_user_id);
    }

    createAssessmentUnit(formdata)
      .then((response) => {
        setformData({
          name: "",
          remark: "",
        });
        handleCloseModal();
        fetchData();
        showNotification(`Assessment Created Successfully.`, "success");
      })
      .catch((error) => {
        showNotification(`${error.response.data.error}`, "error");
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request is complete
      });
  };

  return (
    <div className="assessment-modal">
      <Button
        variant="text"
        onClick={handleShowModal}
        className="save-btn exclude-from-pdf open-btn"
      >
        New Assessment Unit
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Assessment Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="statistical-box">
              <label>Name Of Assessment Unit : </label>&nbsp;&nbsp;
              <input
                type="text"
                name="name"
                className="name-of-assesment"
                value={formData.name}
                onChange={handleInputChange}
                required
              />{" "}
              &nbsp;&nbsp;
              <select
                onChange={(e) => {
                  setformData({
                    ...formData,
                    assigned_user_id: e.target.value,
                  });
                }}
              >
                <option>Assign Users</option>
                {users &&
                  users.map((user) => (
                    <option value={user?.id}>{user?.username}</option>
                  ))}
              </select>
              &nbsp;&nbsp;&nbsp;
              {/* <select >
                <option>iFCRA AUs</option>
                <option>Option 1</option>
                <option>Option 2</option>
              </select> */}
            </div>
            <div className="Write-Justification">
              <div className="inner-body">
                <h3>
                  <img src={BookIcon} alt="icon" /> Write your Justification
                </h3>
                <textarea
                  name="remark"
                  cols="96"
                  rows="5"
                  required
                  maxLength={300}
                  value={formData.remark}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="popup-uplod-btn">
              <FileUpload setUpdatedData={setformData} />
              {user.role === "super-admin" && (
                <button type="submit" className="save-btn-pop">
                  {!isLoading ? (
                    "Approve"
                  ) : (
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: 24 }}
                    />
                  )}
                </button>
              )}

              {user.role !== "super-admin" && (
                <button type="submit" className="save-btn-pop">
                  {!isLoading ? (
                    "Save"
                  ) : (
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: 24 }}
                    />
                  )}
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default AssessmentModal;
