
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
import "../../components/Configuration/configuration.css";
import Delete from "../../assets/image/delete.svg";
import BookIcon from "../../assets/image/book-icon.svg";
import { useAuth } from '../../components/Auth/AuthContext';

const DeleteModal = ({  handleDelete, data, isLoading }) => {
    const [showModal, setShowModal] = useState(false);
    const { user } = useAuth();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    return (
        <>
            <Button
                disabled={!data.isActive}
                variant="text"
                onClick={handleShowModal}
                className={'no-btn'}
            >
                <img src={Delete} alt="icon" />
            </Button >
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="Write-Justification">
                        <div className="inner-body">
                            <h3>
                                <img src={BookIcon} alt="icon" /> Write your Justification
                            </h3>
                            <textarea
                                name='remark'
                                cols="96"
                                rows="5"
                                required
                                maxLength='300'
                                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor."
                            />

                        </div>
                    </div>
                    <div className="popup-uplod-btn">
                        {/* <FileUpload /> */}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a> Reset</a>

                        {user.role === 'super-admin' && (
                            <button type='submit' className="save-btn-pop"
                                onClick={handleDelete}>
                                {!isLoading ? 'Approve' : (
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                )}
                            </button>
                        )}

                        {user.role !== 'super-admin' && (
                            <button type='submit' className="save-btn-pop" onClick={handleDelete}>
                                {!isLoading ? 'Save' : (
                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} />
                                )}
                            </button>
                        )}

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DeleteModal
