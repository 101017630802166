import React, { useEffect, useState } from "react";
import { getAssessmentLogs } from "../../services/ApiService";
import {
  complianceUserStatus,
  consumerBankingStatus,
} from "../../utils/customHooks/checkStatus";
import { DateFormat } from "../../utils/customHooks/DateFormat";
export const ConfigurationLogs = ({ data }) => {
  useEffect(() => {}, [data]);

  const latestItems = data.reduce((map, item) => {
    // Check if the item is the latest for the given name
    if (!map[item.name] || item.created_at > map[item.name].created_at) {
      map[item.name] = item;
    }
    return map;
  }, {});

  const latestData = Object.values(latestItems);

  return (
    <>
      {latestData.map((val) => (
        <React.Fragment key={val.id}>
          <div
            className={`breadcum-partt ${val.status === 0 ? "disabled" : ""}`}
          >
            {val.name === "Initiate IFCRA" && val.name && (
              <i class="fa fa-calendar" aria-hidden="true"></i>
            )}{" "}
            {/* <p>{val.name === "Initiate IFCRA" && val.name}</p> */}
            <span>
              {val.name === "Initiate IFCRA" &&
                (val.status === 0 ? "Awating" : DateFormat(val.created_at))}
            </span>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export const AssessmentLogs = ({ data }) => {
  const [assessmentLogs, setAssessmentLogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data) {
          const consumerStatusResult = consumerBankingStatus(data);
          const complianceStatusResult = complianceUserStatus(data);
          
          const response = await getAssessmentLogs();
          const updatedLogs = response.data.map((log) => {
            if (log.name === "Consumer Banking Submission") {
              return {
                ...log,
                status: consumerStatusResult.hasRisk ? 1 : log.status,
                created_at: consumerStatusResult.latestUpdate,
              };
            } else if (log.name === "Compliance Review") {
              return {
                ...log,
                status: complianceStatusResult.hasRisk ? 1 : log.status,
                created_at: complianceStatusResult.latestUpdate,
              };
            } else {
              return log;
            }
          });

          setAssessmentLogs(updatedLogs);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [data]);

  return (
    <>
      {assessmentLogs.map((val) => (
        <React.Fragment key={val.id}>
          {val.name !== "Revised Assessment" &&
            val.name !== "Assessment Approved" && (
              <div
                className={`breadcum-part ${
                  val.status === 0 ? "disabled" : ""
                }`}
              >
                <p>{val.name}</p>
                <span>
                  {val.status === 0 ? "Awating" : DateFormat(val.created_at)}
                </span>
              </div>
            )}
        </React.Fragment>
      ))}
    </>
  );
};
