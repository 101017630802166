/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import "./Speedometer.css";

const Speedometer = ({ value }) => {
  let ringWidth = 12;
  if (window.screen.width >= 1100 && window.screen.width < 1200) {
    ringWidth = 9;
  } else if (window.screen.width >= 1200 && window.screen.width < 1300) {
    ringWidth = 10;
  } else if (window.screen.width >= 1300 && window.screen.width < 1600) {
    ringWidth = 10;
  } else if (window.screen.width >= 1600 && window.screen.width < 2000) {
    ringWidth = 12;
  } else {
    ringWidth = 12;
  }
  useEffect(() => {
    const svgPath = document.querySelector(".pointer"); // Select the path with the desired class
    if (svgPath) {
      svgPath.setAttribute("rx", "10"); // Set the rx value
      svgPath.setAttribute("ry", "10"); // Set the ry value
    }
  }, []);

  return (
    <div className="gauge-semicircle" style={{margin: "-25px auto" }}>
      <GaugeComponent
        value={value}
        type="radial"
        arc={{
          colorArray: ["#42a121", "#f8b200", "#df4a11"],
          subArcs: [
            { limit: 33.33 },  // First arc covers 0-33.33
            { limit: 66.66 },  // Second arc covers 33.33-66.66
            {}  // Third arc covers the rest (66.66-100)
          ],
          padding: 0.05,
          width: 0.2,
            rounded: false,
            arcWidth: 0.8, // Adjust arc thickness
        }}
        pointer={{
          elastic: false,
          animationDelay: 0,
        }}
      />
    </div>
  );
};

export default Speedometer;
