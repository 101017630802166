import HttpClient from "./HttpClient"; // Import the Axios instance

// Define user-related API endpoints
const endPoints = {
  login: "/api/login",

  getRiskTypes: "/api/v1/risk-types",
  getConfiguration: "/api/v1/get-configuration",

  getAssessmentUnits: "/api/v1/assessment-units",
  createAssessmentUnit: "/api/v1/assessment-unit/create",
  updateAssessmentUnit: (id) => `/api/v1/assessment-unit/update/${id}`,
  deleteAssessmentUnit: (id) => `/api/v1/assessment-unit/delete/${id}`,

  getRiskCategories: "/api/v1/risk-categories",
  updateRiskCategory: (id) => `/api/v1/risk-category/update/${id}`,
  deleteRiskCategory: (id) => `/api/v1/risk-category/delete/${id}`,
  updateSubCategory: (id) => `/api/v1/sub-category/update/${id}`,
  deleteSubCategory: (id) => `/api/v1/sub-category/delete/${id}`,

  getKeyRiskIndicators: "/api/v1/key-risk-indicators",
  updateKRI: (id) => `/api/v1/key-risk-indicator/update/${id}`,
  getConfigurationLogs: "/api/v1/configuration-logs",
  approveAllRequest: "/api/v1/approve-all",
  rejectAllRequest: "/api/v1/reject-all",
  requestReview: "/api/v1/request-for-review",
  resetAll: "/api/v1/reset-all",

  //assessment panels endpoints
  initiateIFCRA: "/assessment/v1/initiate-ifcra",
  getActiveAssessmentData: "/assessment/v1/get-active-assessment-data",
  getActiveAssessments: "/assessment/v1/get-active-assessments",
  updateActiveAssessment: (id) =>
    `/assessment/v1/update-active-assessment/${id}`,
  updateKeyRiskIndicator: (id) =>
    `/assessment/v1/keyRiskIndicator/update/${id}`,
  getAssessmentLogs: "/assessment/v1/assessment-logs",

  getTrendChart: "/assessment/v1/trend-chart",
  getRiskScores: "/assessment/v1/get-risk-scores",
  getResidualRiskScores: "/assessment/v1/get-residual-risk-scores",
  getSystemGeneratedRiskScores: "/assessment/v1/risk-scores-system-generated",

  calculateWeight: "/assessment/v1/calculate-weight",
  procedureCall: "/assessment/v1/procedure-call",

  //images
  uploadImage: "media/upload-image",

  calculateThresholdRisk: "/assessment/v1/threshold-risk",
  callRunnerHistory: "/assessment/v1/history-runner-call",
  thresholdFactor: "/assessment/v1/threshold-factor",
  callWeightRunner: "/assessment/v1/weights-runner-call",
  resetPendingKriWeights: "/assessment/v1/keyRiskIndicator-reset",
  rejectAllKriOverrideRequest: "/assessment/v1/keyRiskIndicator-reject-all",
  approveAllKriOverrideRequest: "/assessment/v1/keyRiskIndicator-approve-all",
  averageAuRiskScores: "/assessment/v1/risk-assessment-score",

  //download attachment apis
  downloadAuAttachment: "/api/v1/download-au",
  downloadRcAttachment: "/api/v1/download-rc",
  downloadKriAttachment: "/api/v1/download-kri",
  downloadAssessmentKriAttachment: "/assessment/v1/download-kris",
  buisnessUsersList: "/api/v1/business-user-list",
};

const formDataHeaders = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

export const login = (BODY) => {
  return HttpClient.post(endPoints.login, BODY);
};

//configurations

export const getRiskTypes = (params) => {
  return HttpClient.get(endPoints.getRiskTypes, {
    params,
    headers: formDataHeaders,
  });
};

export const getConfiguration = () => {
  return HttpClient.get(endPoints.getConfiguration, {
    headers: formDataHeaders,
  });
};

export const getAssessmentUnits = (params) => {
  return HttpClient.get(endPoints.getAssessmentUnits, {
    params,
    headers: formDataHeaders,
  });
};

export const createAssessmentUnit = (BODY) => {
  return HttpClient.post(endPoints.createAssessmentUnit, BODY, {
    headers: formDataHeaders,
  });
};

export const updateAssessmentUnit = (id, BODY) => {
  return HttpClient.post(endPoints.updateAssessmentUnit(id), BODY, {
    headers: formDataHeaders,
  });
};

export const deleteAssessmentUnit = (id) => {
  return HttpClient.delete(endPoints.deleteAssessmentUnit(id), {
    headers: formDataHeaders,
  });
};

export const getRiskCategories = (params) => {
  return HttpClient.get(endPoints.getRiskCategories, {
    params,
    headers: formDataHeaders,
  });
};

export const updateRiskCategory = (id, BODY) => {
  return HttpClient.post(endPoints.updateRiskCategory(id), BODY, {
    headers: formDataHeaders,
  });
};

export const deleteRiskCategory = (id) => {
  return HttpClient.delete(endPoints.deleteRiskCategory(id), {
    headers: formDataHeaders,
  });
};

export const updateSubCategory = (id, BODY) => {
  return HttpClient.post(endPoints.updateSubCategory(id), BODY, {
    headers: formDataHeaders,
  });
};

export const deleteSubCategory = (id) => {
  return HttpClient.delete(endPoints.deleteSubCategory(id), {
    headers: formDataHeaders,
  });
};

export const getKeyRiskIndicators = (params) => {
  return HttpClient.get(endPoints.getKeyRiskIndicators, {
    params,
    headers: formDataHeaders,
  });
};

export const updateKRI = (id, BODY) => {
  return HttpClient.post(endPoints.updateKRI(id), BODY, {
    headers: formDataHeaders,
  });
};

export const getConfigurationLogs = (params) => {
  return HttpClient.get(endPoints.getConfigurationLogs, {
    params,
    headers: formDataHeaders,
  });
};

export const approveAllRequest = () => {
  return HttpClient.get(endPoints.approveAllRequest, {
    headers: formDataHeaders,
  });
};

export const rejectAllRequest = () => {
  return HttpClient.get(endPoints.rejectAllRequest, {
    headers: formDataHeaders,
  });
};

export const requestReview = () => {
  return HttpClient.get(endPoints.requestReview, { headers: formDataHeaders });
};

export const resetAll = () => {
  return HttpClient.get(endPoints.resetAll, { headers: formDataHeaders });
};

//assessments

export const initiateIFCRA = () => {
  return HttpClient.get(endPoints.initiateIFCRA, { headers: formDataHeaders });
};

export const getActiveAssessmentData = () => {
  return HttpClient.get(endPoints.getActiveAssessmentData, {
    headers: formDataHeaders,
  });
};

export const getActiveAssessments = () => {
  return HttpClient.get(endPoints.getActiveAssessments, {
    headers: formDataHeaders,
  });
};

export const updateActiveAssessment = (id) => {
  return HttpClient.post(
    endPoints.updateActiveAssessment(id),
    {},
    {
      headers: formDataHeaders,
    }
  );
};

export const updateKeyRiskIndicator = (id, BODY) => {
  return HttpClient.post(endPoints.updateKeyRiskIndicator(id), BODY, {
    headers: formDataHeaders,
  });
};

export const getAssessmentLogs = () => {
  return HttpClient.get(endPoints.getAssessmentLogs, {
    headers: formDataHeaders,
  });
};

export const uploadImage = (BODY) => {
  return HttpClient.post(endPoints.uploadImage, BODY, {
    headers: formDataHeaders,
  });
};

export const calculateWeight = () => {
  return HttpClient.get(endPoints.calculateWeight, {
    headers: formDataHeaders,
  });
};

export const procedureCall = () => {
  return HttpClient.get(endPoints.procedureCall, { headers: formDataHeaders });
};

export const getTrendChart = () => {
  return HttpClient.get(endPoints.getTrendChart, { headers: formDataHeaders });
};

export const getRiskScores = () => {
  return HttpClient.get(endPoints.getRiskScores, { headers: formDataHeaders });
};

export const getResidualRiskScores = () => {
  return HttpClient.get(endPoints.getResidualRiskScores, {
    headers: formDataHeaders,
  });
};

export const getSystemGeneratedRiskScores = () => {
  return HttpClient.get(endPoints.getSystemGeneratedRiskScores, {
    headers: formDataHeaders,
  });
};

export const calculateThresholdRisk = () => {
  return HttpClient.get(endPoints.calculateThresholdRisk, {
    headers: formDataHeaders,
  });
};

export const callWeightRunner = () => {
  return HttpClient.get(endPoints.callWeightRunner, {
    headers: formDataHeaders,
  });
};

export const thresholdFactor = () => {
  return HttpClient.get(endPoints.thresholdFactor, {
    headers: formDataHeaders,
  });
};

export const callRunnerHistory = () => {
  return HttpClient.get(endPoints.callRunnerHistory, {
    headers: formDataHeaders,
  });
};

export const resetPendingKriWeights = () => {
  return HttpClient.patch(
    endPoints.resetPendingKriWeights,
    {},
    {
      headers: formDataHeaders,
    }
  );
};

export const rejectAllKriOverrideRequest = () => {
  return HttpClient.patch(
    endPoints.rejectAllKriOverrideRequest,
    {},
    {
      headers: formDataHeaders,
    }
  );
};

export const approveAllKriOverrideRequest = () => {
  return HttpClient.patch(
    endPoints.approveAllKriOverrideRequest,
    {},
    {
      headers: formDataHeaders,
    }
  );
};

export const averageAuRiskScores = () => {
  return HttpClient.get(endPoints.averageAuRiskScores, {
    headers: formDataHeaders,
  });
};

export const downloadAuAttachment = (id) => {
  return HttpClient.get(`${endPoints.downloadAuAttachment}/${id}`, {
    responseType: "blob",
    headers: formDataHeaders,
  });
};

export const downloadRcAttachment = (id) => {
  return HttpClient.get(`${endPoints.downloadRcAttachment}/${id}`, {
    responseType: "blob",
    headers: formDataHeaders,
  });
};

export const downloadKriAttachment = (id) => {
  return HttpClient.get(`${endPoints.downloadKriAttachment}/${id}`, {
    responseType: "blob",
    headers: formDataHeaders,
  });
};

export const downloadAssessmentKriAttachment = (id) => {
  return HttpClient.get(`${endPoints.downloadAssessmentKriAttachment}/${id}`, {
    responseType: "blob",
    headers: formDataHeaders,
  });
};

export const buisnessUsersList = () => {
  return HttpClient.get(`${endPoints.buisnessUsersList}`, {
    headers: formDataHeaders,
  });
};
